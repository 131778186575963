import type * as APIv1 from '~~/types/api/v1'

const populateSingle = (single: Record<string, any>, included?: APIv1.IncludedShape) => {
  if (!included || !single.relationships)
    return single

  const relationships = single.relationships

  Object.keys(relationships).forEach((key) => {
    const relationData = relationships[key].data
    const isMultiRelation = Array.isArray(relationData)
    const relationshipData = (isMultiRelation ? relationData : [relationData]).map((data) => {
      const relatedInclude = included.find(inc => inc.type === data.type && inc.id === data.id)
      if (relatedInclude)
        return populateSingle(relatedInclude, included)

      return populateSingle(data, included)
    })

    relationships[key].data = isMultiRelation ? relationshipData : relationshipData[0]
  })

  single.relationships = relationships

  return single
}

export const populateRelationships = <T extends APIv1.Response>(
  { data, included, ...others }: T,
): T => {
  if (Array.isArray(data))
    data = data.map(d => populateSingle(d, included))

  else
    data = populateSingle(data, included)

  return {
    data,
    included,
    ...others,
  } as T
}
