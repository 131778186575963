import { createQueryKeys } from '@lukemorales/query-key-factory'
import { responseDataTypeName, useFetchReferral } from './useFetchReferral'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    workspaceId,
  }: {
    workspaceId: MaybeRef<string>
  }) => ({
    queryKey: [workspaceId],
  }),
})

const all = (useQueryOptions: UseQueryOptionsWithoutQueryFn = {}) => {
  const { getAll } = useFetchReferral()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
    }),
    queryFn: () => getAll({
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryReferral = {
  all,
}
