import { createQueryKeys } from '@lukemorales/query-key-factory'
import { responseDataTypeName, useFetchDocumentTag } from './useFetchDocumentTag'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({ workspaceId }: { workspaceId: MaybeRef<string> }) => ({
    queryKey: [workspaceId],
  }),
})

const all = ({ useQueryOptions = {} }: { useQueryOptions?: UseQueryOptionsWithoutQueryFn } = {}) => {
  const { getAll } = useFetchDocumentTag()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({ workspaceId: activeWorkspaceId as MaybeRef<string> }),
    queryFn: () => getAll({ workspaceId: unref(activeWorkspaceId) ?? '' }),
    enabled: () => !!unref(activeWorkspaceId),
    select: ({ data }) => data,
    ...useQueryOptions,
  })
}

export const useQueryDocumentTag = {
  all,
}
