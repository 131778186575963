import type { Client } from '@bugsnag/js'

export default defineNuxtPlugin((nuxtApp) => {
  const { user, workspace } = useAuth()

  const bugsnag = nuxtApp.$bugsnag as Client | undefined

  bugsnag?.addOnError((event) => {
    // add user information to the error
    if (user.value) {
      event.setUser(user.value.id, user.value.attributes.email)
      if (workspace.value) {
        event.addMetadata('workspace', {
          id: workspace.value.id,
          displayName: workspace.value.attributes.displayName,
        })
      }
    }
  })
})
