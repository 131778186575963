import { createQueryKeys } from '@lukemorales/query-key-factory'
import { type ParametersGetAll, responseDataTypeName, useFetchParty } from './useFetchParty'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    workspaceId,
    params,
  }:
  {
    workspaceId: MaybeRef<string>
    params?: MaybeRef<ParametersGetAll>
  },
  ) => ({
    queryKey: [
      workspaceId,
      ...(params ? [params] : []),
    ],
  }),
})

const all = (
  {
    params,
    useQueryOptions = {},
  }: {
    params?: MaybeRef<ParametersGetAll> | undefined
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  } = {},
) => {
  const { getAll } = useFetchParty()
  const { activeWorkspaceId } = useAuth()

  return useQuery(
    {
      ...queryKeys.all({
        workspaceId: activeWorkspaceId as MaybeRef<string>,
        params,
      }),
      queryFn: () => getAll({
        params: unref(params),
        workspaceId: unref(activeWorkspaceId) ?? '',
      }),
      enabled: () => !!unref(activeWorkspaceId),
      ...useQueryOptions,
    },
  )
}

export const useQueryParty = {
  all,
}
