import { useApiV2FetchFactory } from '../useApiV2FetchFactory'
import type { WithNegated } from '~/types/util/string'
import type { CollectionRequestPaginationParams } from '~/types/api/v2'

export type WorkspacePermissionData = App.Data.Workspace.WorkspacePermissionData
export const responseDataTypeName: WorkspacePermissionData['_type'] = 'workspace_permission'

export type IncludableField =
  'user' |
  'invite' |
  'permits' |
  'inviter' |
  'folder_permissions' |
  'folder_permissions.folder.breadcrumb'

type SortCriterion = 'name' | 'type'

interface FilterParameters {
  'filter[search]'?: string
  'filter[user_id]'?: string
  'filter[type]'?: App.Internals.Enums.UserType[]
}

export type ParametersGetAll = {
  sort?: WithNegated<SortCriterion>[]
} & FilterParameters & CollectionRequestPaginationParams

export type ParametersGetAllUnpaginated = {
  sort?: WithNegated<SortCriterion>[]
} & FilterParameters

export const useFetchWorkspacePermission = () => {
  const url = '/v2/settings/workspace-permissions'

  const { createAllMethods } = useApiV2FetchFactory<WorkspacePermissionData, IncludableField>(url)

  type CreatePayload = App.Data.Payloads.WorkspacePermissions.CreateWorkspacePermissionPayload
  type UpdatePayload = App.Data.Payloads.WorkspacePermissions.UpdateWorkspacePermissionPayload

  return createAllMethods<ParametersGetAllUnpaginated, CreatePayload, UpdatePayload>()
}
