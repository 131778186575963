import type { Story } from './general'

export const contentType = 'app_config' as const

interface FaqItemContent {
  _uid: string
  answer: string
  component: 'faq_item'
  question: string
}

interface AssetContent {
  filename: string
}

interface LogoContent {
  logo_black: AssetContent
}

interface QuoteContent {
  quote: string
  name: string
}

export interface AppConfigContent {
  templates_popular_searches: string
  activation_nagger_logos: string[] | Story<AssetContent>[]
  subscribe_modal_logo_cloud: string[] | Story<LogoContent>[]
  subscribe_modal_quotes: string[] | Story<QuoteContent>[]
  subscribe_modal_people?: AssetContent
  faq_items?: FaqItemContent[]
}

export type ResolvableRelation =
  'activation_nagger_logos' |
  'subscribe_modal_logo_cloud' |
  'subscribe_modal_quotes'
