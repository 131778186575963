import { createQueryKeys } from '@lukemorales/query-key-factory'
import { type ParametersGetAll, responseDataType, useFetchAccountItem } from './useFetchAccountItem'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataType, {
  getAll: ({
    workspaceId,
    params,
  }: {
    workspaceId: MaybeRef<string>
    params: MaybeRef<ParametersGetAll>
  }) => ({
    queryKey: [workspaceId, ...(params ? [params] : [])],
  }),
})

const getAll = ({
  params,
  useQueryOptions = {},
}: {
  params: MaybeRef<ParametersGetAll>
  useQueryOptions: UseQueryOptionsWithoutQueryFn
}) => {
  const { getAll } = useFetchAccountItem()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.getAll({
      workspaceId: activeWorkspaceId.value as MaybeRef<string>,
      params,
    }),
    queryFn: () => getAll({
      workspaceId: toValue(activeWorkspaceId.value) ?? '',
      params: unref(params),
      ...useQueryOptions,
    }),
  })
}

export const useQueryAccountItem = {
  getAll,
}
