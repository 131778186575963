import { useFetchWithDefaults } from '../../useFetchWithDefaults'
import type { WorkspaceIdentifier } from '../../types'
import type { PriceListAddonPrice, PriceListItemPrice } from '../../price-list/useFetchPriceList'

export interface EstimateLineData {
  id: string
  type: 'addon' | 'plan'
  description: string
  quantity: number
  unitAmount: number
}

export interface EstimateActiveCoupon {
  id: string
  amount: number
  description?: string
}

interface EstimateDiscount {
  // eslint-disable-next-line ts/ban-types
  type: 'excess_payments' | 'creditsApplied' | string & {}
  amount: number
}

export interface Estimate {
  amountTotal: number
  amountDue: number
  taxAmount: number
  taxRate: number
  nextBillingDate: number
  currency: string
  activeCoupon?: EstimateActiveCoupon
  discounts: EstimateDiscount[]
  lines: EstimateLineData[]
}

export interface EstimatePayload {
  planPriceId: PriceListItemPrice['id']
  coupon?: string
  addonPriceId?: PriceListAddonPrice['price_id']
}

export const responseDataTypeName = 'subscription-estimate'

export const useFetchSubscriptionEstimate = () => {
  const getUrl = (workspaceId: string) => `/me/companies/${workspaceId}/subscription/estimate`

  const { post } = useFetchWithDefaults()

  const getEstimate = ({
    workspaceId,
    payload,
  }: WorkspaceIdentifier & {
    payload: EstimatePayload
  }) =>
    post<Estimate>({
      url: getUrl(workspaceId),
      workspaceId,
      fetchOptions: {
        body: {
          plan: payload.planPriceId,
          coupon: payload.coupon || null,
          ...(payload.addonPriceId ? { addons: [{ id: payload.addonPriceId, quantity: 1 }] } : {}),
        },
      },
    })

  return {
    getEstimate,
  }
}
