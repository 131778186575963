import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { WorkspaceIdentifier } from '../types'
import type { DocumentsMetaTag } from '~/types'
import type { Response } from '~/types/api/v2'

export const responseDataTypeName: DocumentsMetaTag['_type'] = 'tag'

export const useFetchDocumentTag = () => {
  const { get } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) =>
    get<Response<DocumentsMetaTag[]>>({
      url: '/v2/metadata/tags',
      workspaceId,
    })

  return {
    getAll,
  }
}
