import { createQueryKeys } from '@lukemorales/query-key-factory'
import { type IncludableField, responseDataTypeName, useFetchDocumentReminder } from './useFetchDocumentReminder'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  byDocumentId: ({
    documentId,
    fieldsToInclude,
  }: {
    documentId: MaybeRef<string>
    fieldsToInclude?: IncludableField[]
  }) => ({
    queryKey: [
      documentId,
      ...(fieldsToInclude ? [{ fieldsToInclude: fieldsToInclude.sort() }] : []),
    ],
  }),
})

const byDocumentId = <T extends IncludableField[] = []>({
  documentId,
  fieldsToInclude,
  useQueryOptions = {},
}: {
  documentId: MaybeRef<string>
  fieldsToInclude?: T
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { byDocumentId } = useFetchDocumentReminder()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.byDocumentId({
      documentId,
      fieldsToInclude,
    }),
    queryFn: () => byDocumentId({
      documentId: unref(documentId),
      workspaceId: unref(activeWorkspaceId) ?? '',
      fieldsToInclude: fieldsToInclude ?? [],
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryDocumentReminder = {
  byDocumentId,
}
