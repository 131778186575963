import dayjs from 'dayjs'
import type { ConsentCookies } from '~~/types/cookies'

export default () => {
  const isOpen = useState('cookieConsentOpen', () => false)
  const open = () => {
    isOpen.value = true
  }

  const { public: { cookieDomain } } = useRuntimeConfig()

  const cookies = useCookie<ConsentCookies | undefined>('CookieConsent', {
    expires: dayjs().add(1, 'year').toDate(),
    path: '/',
    domain: cookieDomain && location.host.includes(cookieDomain) ? cookieDomain : undefined,
  })

  const hasConsented = computed(() => !!cookies.value)

  return {
    cookies,
    hasConsented,
    isOpen,
    open,
  }
}
