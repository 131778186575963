const v2Routes = [
  '/2',
  '/welcome',
  '/subscription',
]

export default defineNuxtRouteMiddleware(async (to) => {
  if (v2Routes.some(r => to.path.startsWith(r)))
    return

  const returnPath = `${useRuntimeConfig().public.appV1Url}${to.fullPath}`
  navigateTo(returnPath, { external: true })

  return abortNavigation()
})
