import type { WorkspaceIdentifier } from '../types'
import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { Features } from '~/types/auth'

export const useFetchFeature = () => {
  const url = '/me/features'

  const { get } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) =>
    get<{ data: Features }>({ url, workspaceId })

  return {
    getAll,
  }
}
