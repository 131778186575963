import Pusher from 'pusher-js'

export interface PusherContext {
  activePusherInstance: ComputedRef<Pusher | undefined>
  subscribeToContractChanges: (contractId: string, callback: () => any) => void
}

const eventNames = ['signature-updated', 'contract-completed', 'signature-log-updated'] as const

const PUSHER_CONTEXT_KEY: InjectionKey<PusherContext> = Symbol('pusher')

export const usePusherProvider = () => {
  const { apiToken, activeWorkspaceId } = useAuth()
  const { public: { pusherApp, pusherDebug, internalApiUrl } } = useRuntimeConfig()
  Pusher.logToConsole = pusherDebug

  const pusherInstances = ref(new Map<string, Pusher>())

  const createPusherInstance = () => new Pusher(pusherApp, {
    cluster: 'eu',
    channelAuthorization: {
      transport: 'ajax',
      endpoint: `${internalApiUrl}/pusher/auth`,
      headers: {
        Authorization: `Bearer ${apiToken.value}`,
        ActiveAccount: activeWorkspaceId.value,
      },
    },
  })

  const activePusherInstance = computed<Pusher | undefined>(() =>
    activeWorkspaceId.value ? pusherInstances.value.get(activeWorkspaceId.value) as Pusher : undefined,
  )

  watch(activeWorkspaceId, () => {
    if (!activeWorkspaceId.value || pusherInstances.value.has(activeWorkspaceId.value))
      return

    pusherInstances.value.set(activeWorkspaceId.value, createPusherInstance())
  }, { immediate: true })

  // TODO The following hacky code will change in DEV-6095.
  const eventListeners = new Map<string, () => void>()

  const callEventListener = (contractId: string) =>
    eventListeners.get(contractId)?.()

  const subscribeToContractChanges = (contractId: string, callback: () => any) => {
    if (!activePusherInstance.value)
      return

    const listener = eventListeners.get(contractId)

    if (listener)
      return

    eventListeners.set(contractId, callback)

    const channelName = `private-contract-signature-${contractId}`

    if (!Object.keys(activePusherInstance.value.channels.channels).includes(channelName)) {
      const channel = activePusherInstance.value.subscribe(channelName)

      eventNames.forEach(name =>
        channel.bind(name, () => callEventListener(contractId)),
      )
    }
  }

  onBeforeUnmount(() => {
    Array.from(pusherInstances.value).forEach(([_, instance]) => instance.disconnect())
  })

  const toProvide: PusherContext = {
    activePusherInstance,
    subscribeToContractChanges,
  }

  provide(PUSHER_CONTEXT_KEY, toProvide)

  return toProvide
}

export const usePusher = () => {
  const context = inject(PUSHER_CONTEXT_KEY)

  if (!context)
    throw new Error('usePusher must be used together with usePusherProvider')

  return context
}
