import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { WorkspaceIdentifier } from '../types'
import type * as API from '~/types/api'
import type { Workspace } from '~/types/auth'
import { populateRelationships } from '~/utils/api/v1'

export const responseDataTypeName: Workspace['type'] = 'company'

export interface ParametersGetAll {
  limit: number
}

export type IncludableField = 'role' | 'partner' | 'preferences'

export type UpdateWorkspacePayload = Partial<Workspace['attributes']>
export interface UpdateWorkspaceMetaPayload {
  avatar?: {
    content: string
    filename: string
  }
  logo?: {
    content: string
    filename: string
  }
}

export const useFetchWorkspace = () => {
  const url = '/me/companies'

  const { get, put } = useFetchWithDefaults()

  const getAll = ({ params }: { params?: ParametersGetAll } = {}) =>
    get<API.v1.Response<Workspace[]>>({ url, fetchOptions: { params } })

  const getById = ({
    workspaceId,
    fieldsToInclude,
  }: WorkspaceIdentifier & { fieldsToInclude?: IncludableField[] }) =>
    get<API.v1.Response<Workspace>>({
      url: `${url}/${workspaceId}`,
      workspaceId,
      fetchOptions: {
        params: { include: fieldsToInclude ?? [] },
      },
    }).then(populateRelationships)

  const update = ({
    workspaceId,
    payload,
    meta,
  }: WorkspaceIdentifier & { payload: UpdateWorkspacePayload, meta?: UpdateWorkspaceMetaPayload }) => put<Workspace>({
    url: `${url}/${workspaceId}`,
    workspaceId,
    fetchOptions: {
      body: {
        data: {
          id: workspaceId,
          type: 'company',
          attributes: payload,
          ...(meta ? { meta } : {}),
        },
      },
    },
  })

  return {
    getAll,
    getById,
    update,
  }
}
