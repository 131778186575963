<script lang="ts" setup>
useHead({
  titleTemplate: title => title ? `${title} - Docue` : 'Docue',
})
</script>

<template>
  <UiTooltipProvider>
    <NuxtLayout class="h-full">
      <NuxtPage />
    </NuxtLayout>
  </UiTooltipProvider>
</template>
