import { createQueryKeys } from '@lukemorales/query-key-factory'
import { useFetchLicense } from './useFetchLicense'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys('license', {
  all: ({ workspaceId }: { workspaceId: MaybeRef<string> }) => ({
    queryKey: [workspaceId],
  }),
})

const all = ({ useQueryOptions = {} }: { useQueryOptions?: UseQueryOptionsWithoutQueryFn } = {}) => {
  const { getAll } = useFetchLicense()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({ workspaceId: activeWorkspaceId as MaybeRef<string> }),
    queryFn: () => getAll({ workspaceId: unref(activeWorkspaceId) ?? '' }),
    enabled: () => !!unref(activeWorkspaceId),
    select: ({ data }) => data, // Is fine to use. API does not return pagination info.
    ...useQueryOptions,
  })
}

export const useQueryLicense = {
  all,
}
