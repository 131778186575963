import { createQueryKeys } from '@lukemorales/query-key-factory'
import { type GetAllParams, responseDataTypeName, useFetchIntelligenceCompanies } from './useFetchIntelligenceCompanies'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  getAll: ({
    workspaceId,
    params,
  }: {
    workspaceId: MaybeRef<string>
    params: MaybeRef<GetAllParams>
  }) => ({ queryKey: [
    workspaceId,
    params,
  ] }),
})

const getAll = ({ params, useQueryOptions = {} }: {
  params: MaybeRef<GetAllParams>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getAll } = useFetchIntelligenceCompanies()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.getAll({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      params,
    }),
    queryFn: () => getAll({
      workspaceId: unref(activeWorkspaceId.value) ?? '',
      params: unref(params),
    }),
    select: ({ data }) => data,
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryIntelligenceCompanies = {
  getAll,
}
