import { createQueryKeys } from '@lukemorales/query-key-factory'
import { useQueryClientUtils } from '../useQueryClientUtils'
import { responseDataTypeName, useFetchSubscription } from './useFetchSubscription'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'
import { queryKeys as featureQueryKeys } from '~/composables/api/feature/useQueryFeature'
import { queryKeys as balanceQueryKeys } from '~/composables/api/balance/useQueryBalance'
import { queryKeys as configQueryKeys } from '~/composables/api/config/useQueryConfig.js'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({ workspaceId }: { workspaceId: MaybeRef<string> }) => ({ queryKey: [workspaceId] }),
})

const all = ({ useQueryOptions = {} }: { useQueryOptions?: UseQueryOptionsWithoutQueryFn } = {}) => {
  const { getAll } = useFetchSubscription()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({ workspaceId: activeWorkspaceId as MaybeRef<string> }),
    queryFn: () => getAll({ workspaceId: unref(activeWorkspaceId) ?? '' }),
    enabled: () => !!unref(activeWorkspaceId),
    select: data => data.data, // Is fine to use. API does not return pagination info.
    ...useQueryOptions,
  })
}

const create = () => {
  const { create: mutationFn } = useFetchSubscription()
  const { invalidateQueries, cancelQueries } = useQueryClientUtils()
  const { fetchActiveWorkspaceData } = useAuth()

  return useMutation({
    mutationFn,
    onMutate: () => {
      cancelQueries([
        queryKeys.all._def,
        featureQueryKeys.all._def,
        balanceQueryKeys.all._def,
        configQueryKeys.current._def,
      ])
    },
    onSettled: () => {
      fetchActiveWorkspaceData({ refresh: true })
      invalidateQueries([
        queryKeys.all._def,
        featureQueryKeys.all._def,
        balanceQueryKeys.all._def,
        configQueryKeys.current._def,
      ])
    },
  })
}

export const useQuerySubscription = {
  all,
  create,
}
