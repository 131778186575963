import type { WorkspaceIdentifier } from '../types'
import { useFetchWithDefaults } from '../useFetchWithDefaults'

export interface BalanceData {
  balance: Record< 'free-contract' | 'signature' | 'signature-subscription', number>
}

export const useFetchBalance = () => {
  const url = '/me/records/balance'

  const { get } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) =>
    get<BalanceData>({ url, workspaceId })

  return {
    getAll,
  }
}
