import type { WorkspaceIdentifier } from '../types'
import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { CollectionResponse } from '~/types/api/v2'

export const responseDataTypeName: App.Data.ReferralCodeData['_type'] = 'referral_code'

export type ReferralCodeResponse = Pick<CollectionResponse<App.Data.ReferralCodeData>, 'data'>

export const useFetchReferral = () => {
  const url = '/v2/referral-codes'

  const { get, post } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) => get<ReferralCodeResponse>({
    url,
    workspaceId,
  })

  const init = ({ workspaceId }: WorkspaceIdentifier) => post({
    url,
    workspaceId,
  })

  return {
    getAll,
    init,
  }
}
