import { createQueryKeys } from '@lukemorales/query-key-factory'
import { responseDataTypeName, useFetchSubscriptionEstimate } from './useFetchSubscriptionEstimate'
import type { EstimatePayload } from './useFetchSubscriptionEstimate'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  getEstimate: ({
    workspaceId,
    payload,
  }: {
    workspaceId: MaybeRef<string>
    payload: MaybeRef<EstimatePayload>
  }) => ({
    queryKey: [
      workspaceId,
      payload,
    ],
  }),
})

const getEstimate = ({
  payload,
  useQueryOptions = {},
}: {
  payload: MaybeRef<EstimatePayload>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getEstimate } = useFetchSubscriptionEstimate()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.getEstimate({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      payload,
    }),
    queryFn: () => getEstimate({
      workspaceId: unref(activeWorkspaceId) ?? '',
      payload: unref(payload),
    }),
    ...useQueryOptions,
  })
}

export const useQuerySubscriptionEstimate = {
  getEstimate,
}
