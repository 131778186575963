import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { WorkspaceIdentifier } from '../types'
import type { PaymentSource } from '../../../types/auth'
import type { Response } from '~/types/api/v1'

export const responseDataTypeName: PaymentSource['type'] = 'payment_source'

export interface PaymentSourceCreatePayload {
  stripeIntentId: string
  replacePrimary?: boolean
}

export const useFetchPaymentSource = () => {
  const url = '/me/paymentSources'

  const { get, post, deleteRequest } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) => get<Response<PaymentSource[]>>({ url, workspaceId })

  const create = ({
    workspaceId,
    payload,
  }: WorkspaceIdentifier & { payload: PaymentSourceCreatePayload }) =>
    post<Response<PaymentSource>>({ url, workspaceId, fetchOptions: { body: payload } })

  const drop = ({
    workspaceId,
    paymentSourceId,
  }: WorkspaceIdentifier & { paymentSourceId: string }) =>
    deleteRequest<void>({ url: `${url}/${paymentSourceId}`, workspaceId })

  return {
    getAll,
    create,
    drop,
  }
}
