export default defineNuxtPlugin(() => {
  // The following check is required due to the use of a child Nuxt app embedded within the app via an iframe.
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.get('is-child-app') === 'true')
    return

  const { public: { gtmId } } = useRuntimeConfig()

  const push = (event: Record<string, any>) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(event)
  }

  const mount = () => {
    push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
    window.document.body.appendChild(script)
  }

  const router = useRouter()

  /**
   * Push navigation events for tagManager,
   * logic copied from Nuxt2 gtm plugin
   * https://github.com/nuxt-community/gtm-module/blob/master/lib/plugin.js#L67
   */
  router.afterEach((to) => {
    setTimeout(() => {
      push({
        routeName: to.name,
        pageType: 'pageView',
        pageUrl: to.fullPath,
        pageTitle: (typeof document !== 'undefined' && document.title) || '',
        event: 'nuxtRoute',
      })
    }, 250)
  })

  mount()

  return {
    provide: {
      gtm: {
        push,
      },
    },
  }
})
