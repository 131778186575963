import revive_payload_client_gBor5OZNui from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_b5AembhrwW from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0bIiycuUvc from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_pAgjtg8Zhd from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3irYciDBcz from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jPQ6e2Xe4U from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GpotXhH435 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_kyW5TnbsLC from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_tj25okb7lu6pkkm6ypc4whj6y4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OnesggRaDs from "/vercel/path0/node_modules/.pnpm/dayjs-nuxt@2.1.9_magicast@0.2.11_rollup@4.18.0/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_V5L8q66jyq from "/vercel/path0/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.2.1_@tanstack+vue-query@5.51.21_vue@3.4.35_typescript@5.5.3___n_5z4vouqkjjumziqotbnhpsacbe/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_HDUXJV8zNT from "/vercel/path0/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.22.7_magicast@0.2.11_rollup@4.18.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_d4ciknD2Fu from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.2.11_rollup@4.18.0_vue@3.4.35_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_NKP07v2yKD from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.4.0_magicast@0.2.11_rollup@4.18.0_vue@3.4.35_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import tooltip_hCDS0PLZBq from "/vercel/path0/node_modules/.pnpm/@docue+docue-ui-v2@3.2.0_magicast@0.2.11_nuxt@3.12.4_@opentelemetry+api@1.9.0_@parcel+watcher_r425khg54fnpfpeja42bk5qd4a/node_modules/@docue/docue-ui-v2/ui-plugins/tooltip.ts";
import bugsnag_tkMQfOjcVZ from "/vercel/path0/apps/app/plugins/bugsnag.ts";
import gtm_client_OzmBuHLRIb from "/vercel/path0/apps/app/plugins/gtm.client.ts";
import intercom_client_vr7NPky0pK from "/vercel/path0/apps/app/plugins/intercom.client.ts";
import legacy_client_SwAm5Z4uSi from "/vercel/path0/apps/app/plugins/legacy.client.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/apps/app/plugins/posthog.client.ts";
export default [
  revive_payload_client_gBor5OZNui,
  unhead_b5AembhrwW,
  router_0bIiycuUvc,
  payload_client_pAgjtg8Zhd,
  navigation_repaint_client_3irYciDBcz,
  check_outdated_build_client_jPQ6e2Xe4U,
  chunk_reload_client_GpotXhH435,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kyW5TnbsLC,
  plugin_OnesggRaDs,
  plugin_V5L8q66jyq,
  plugin_HDUXJV8zNT,
  switch_locale_path_ssr_d4ciknD2Fu,
  i18n_NKP07v2yKD,
  tooltip_hCDS0PLZBq,
  bugsnag_tkMQfOjcVZ,
  gtm_client_OzmBuHLRIb,
  intercom_client_vr7NPky0pK,
  legacy_client_SwAm5Z4uSi,
  posthog_client_fUucxKWhZE
]