import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { TemplateCategoryData } from '../template-category/useFetchTemplateCategory'
import type { WorkspaceIdentifier } from '../types'
import type { SignableContract } from '../contract/types'
import type { Language, SignableDocument } from '~/types'
import type { Model, Relationship, Response } from '~/types/api/v1'
import type { Response as V2Response } from '~/types/api/v2'
import type { WithNegated } from '~/types/util/string'
import { populateRelationships } from '~/utils/api/v1'

export const responseDataTypeName: TemplateData['type'] = 'template'

type SortCriterion = 'name'

interface SortParams {
  sort?: WithNegated<SortCriterion>[]
}

export type ParametersGetAll = {
  page?: number
  per_page?: number
  language?: Language
  categoryId?: string
  collectionId?: string
} & SortParams

export type ParametersGetRelated = {
  page?: number
  per_page?: number
} & SortParams

export interface ParametersGetAllByCollectionId {
  'filter[language]'?: Language
}

interface Relationships {
  categories?: Relationship<TemplateCategoryData[]>
}

export type IncludableFields = keyof Relationships

export type JurisdictionCode =
  'FI' |
  'DE' |
  'SE' |
  'GB-ENG' |
  'GB-WLS' |
  'GB-SCT' |
  'GB-NIR' |
  'GB-EAW' // England and Wales

export interface TemplateDataAttributes {
  // TODO complete the type in DEV-6169
  createdAt: string
  updatedAt: string
  deletedAt: string
  type: 'generic' | 'tailored'
  state: 'active' | 'hidden'
  name: string
  contractName: string
  categoryMetas: string[]
  language: Language
  shortDescription: string
  descriptionHtml: string
  unitPrice: number
  unitPriceWithTax: number
  videoIds: string[] | null
  jurisdictions: {
    code: JurisdictionCode
    country: string
  }[]
}

export type TemplateData = Model<TemplateDataAttributes, Relationships> & {
  type: 'template'
}

export interface CreateContractPayload {
  accountItemMetas: string[]
  name: string
}

export interface CreateContractPayloadV2 {
  folder_id: string
  title: string
}

export interface TemplateIdentifier { id: string }

export const useFetchTemplate = () => {
  const { get, post } = useFetchWithDefaults()

  const getById = (
    {
      id,
      workspaceId,
      fieldsToInclude,
    }: {
      id: string
      workspaceId: string
      fieldsToInclude?: IncludableFields[]
    }) => get<Response<TemplateData>>({
    url: `/me/templates/${id}`,
    workspaceId,
    fetchOptions: { params: {
      ...(fieldsToInclude ? { include: fieldsToInclude } : {}),
    } },
  }).then(populateRelationships)

  const getAll = (
    {
      params,
      workspaceId,
    }: {
      params?: ParametersGetAll
      workspaceId: string
    }) => {
    const getUrl = () => params?.categoryId ? `/categories/${params.categoryId}/templates` : '/templates'

    return get<Response<TemplateData[]>>({
      url: getUrl(),
      workspaceId,
      fetchOptions: { params: {
        ...params,
        ...(!params?.categoryId && params?.per_page
          ? {
              limit: params?.per_page, // the categories endpoint uses per_page instead of limit
            }
          : {}),
      } },
    })
  }

  const getAllByCollectionId = ({
    collectionId,
    workspaceId,
    params,
  }: {
    collectionId: string
    workspaceId: string
    params?: ParametersGetAllByCollectionId
  }) =>
    get<Response<TemplateData[]>>({
      url: `/me/companies/${workspaceId}/templateCollections/${collectionId}/templates`,
      workspaceId,
      fetchOptions: { params },
    })

  const getRelated = ({
    id,
    workspaceId,
    params,
  }: {
    params?: ParametersGetRelated
  } & TemplateIdentifier & WorkspaceIdentifier) =>
    get<Response<TemplateData[]>>({
      url: `/templates/${id}/related`,
      workspaceId,
      fetchOptions: { params },
    })

  type CreateContractPayloadType<IsNewArchiveEnabled extends boolean> =
    IsNewArchiveEnabled extends true ? CreateContractPayloadV2 : CreateContractPayload

  const createContractFromTemplate = <IsNewArchiveEnabled extends boolean>({
    id,
    workspaceId,
    payload,
    newArchiveEnabled,
    isUserTemplate,
  }: {
    payload: CreateContractPayloadType<IsNewArchiveEnabled>
    newArchiveEnabled: IsNewArchiveEnabled
    isUserTemplate: boolean
  } & TemplateIdentifier & WorkspaceIdentifier) => {
    const getUrl = (id: string) =>
      `${newArchiveEnabled ? '/v2/' : '/'}${isUserTemplate ? 'user-templates' : 'templates'}/${id}/start`

    return post<Response<SignableContract> | V2Response<SignableDocument>>({
      url: getUrl(id),
      workspaceId,
      fetchOptions: { body: payload },
    })
  }

  return {
    getById,
    getAll,
    getAllByCollectionId,
    getRelated,
    createContractFromTemplate,
  }
}
