import { defu } from 'defu'
import type { HTTPMethod } from 'h3'
import type { FetchContext, FetchOptions } from 'ofetch'

export const useFetchWithDefaults = () => {
  const config = useRuntimeConfig()
  const { language } = useLanguage()
  const { apiToken } = useAuth()
  const { invalidAuthResponseInterceptor } = useApiInterceptor()

  const defaultFetchOptions = computed<FetchOptions>(() => ({
    baseURL: config.public.internalApiUrl,
    headers: {
      accept: 'application/json',
      authorization: apiToken.value ? `Bearer ${apiToken.value}` : '',
      'X-Language': language.value,
      'X-App-Version': config.public.appVersion,
    },
    onRequest: ({ options }) => {
      if (options.params) {
        const paramsToJoin = Object.entries(options.params)
          .filter(([key, value]) =>
            Array.isArray(value) && (
              key === 'include'
              || key === 'sort'
              || key.startsWith('filter[')),
          )

        paramsToJoin.forEach(([key, value]) => {
          if (options.params) // to satisfy typescript
            options.params[key] = value.join(',')
        })
      }
    },
    onResponseError: invalidAuthResponseInterceptor,
  }))

  const fetch = (httpMethod: HTTPMethod) =>
    <ResponseT>({
      url,
      fetchOptions = {},
      workspaceId,
    }: {
      url: string
      fetchOptions?: Omit<FetchOptions, 'method'>
      workspaceId?: string
    }) =>
      $fetch<ResponseT>(
        url,
        defu(
          {
            onRequest: (context: FetchContext) => {
              fetchOptions.onRequest?.(context)
              defaultFetchOptions.value.onRequest?.(context)
            },
          },
          fetchOptions,
          {
            method: httpMethod,
          },
          workspaceId
            ? {
                headers: {
                  ActiveAccount: workspaceId,
                },
              }
            : {},
          defaultFetchOptions.value,
        ),
      )

  return {
    get: fetch('GET'),
    put: fetch('PUT'),
    post: fetch('POST'),
    patch: fetch('PATCH'),
    deleteRequest: fetch('DELETE'),
  }
}
