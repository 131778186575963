import { createQueryKeys } from '@lukemorales/query-key-factory'
import { useQueryClientUtils } from '../useQueryClientUtils'
import { responseDataTypeName, useFetchContract } from './useFetchContract'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  byId: ({ id }: { id: MaybeRef<string> }) =>
    ({ queryKey: [id] }),
})

const byId = (
  {
    id,
    useQueryOptions = {},
  }:
  {
    id: MaybeRef<string>
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  },
) => {
  const { getById } = useFetchContract()
  const { activeWorkspaceId } = useAuth()

  const queryClient = useQueryClient()
  const { invalidateRelationalCacheDataByType } = useQueryClientUtils()

  const invalidate = () => {
    queryClient.invalidateQueries({
      queryKey: queryKeys.byId({ id }).queryKey,
    })

    invalidateRelationalCacheDataByType(responseDataTypeName)
  }

  const { subscribeToContractChanges } = usePusher()

  watch(() => id, () => {
    subscribeToContractChanges(unref(id), invalidate)
    // TODO DEV-6095 prevent double invalidation (one from mutation, one from pusher)
  }, { immediate: true })

  return useQuery({
    ...queryKeys.byId({ id }),
    queryFn: () => getById({
      contractId: unref(id),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryContract = {
  byId,
}
