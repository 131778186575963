import { useFetchWorkspace } from '~/composables/api/workspace/useFetchWorkspace'

export default defineNuxtRouteMiddleware(async (to) => {
  const { user, apiToken, activeWorkspaceId, fetchActiveWorkspaceData } = useAuth()
  const { language } = useLanguage()

  useActiveWorkspaceStorageListener()

  const { public: { appV1Url } } = useRuntimeConfig()
  const loginPath = `${appV1Url}/login?return=${encodeURIComponent(location.href)}`

  const createWorkspacePath = `${appV1Url}/signup/create-workspace?fallback=true&return=${encodeURIComponent(location.href)}`

  if (apiToken.value && activeWorkspaceId.value) {
    try {
      user.value ??= await useApi().me().then(r => r.data)
      language.value = user.value!.attributes.language!

      if (activeWorkspaceId.value === 'personal') {
        // TODO DEV-5797 refactor this file
        const { data: workspaces } = await useFetchWorkspace().getAll({ params: { limit: 1 } })
        if (!workspaces.length && !to.meta.public)
          return navigateTo(createWorkspacePath, { external: true })

        return
      }
      else {
        await fetchActiveWorkspaceData({ refresh: false })
      }

      return
    }
    catch (e) {
      console.error(e)
      // Clean token if request fails
      apiToken.value = null
    }
  }

  if (to.meta.public)
    return

  await navigateTo(loginPath, { external: true })
  return abortNavigation()
})
