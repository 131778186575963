import type { WorkspaceIdentifier } from '../types'
import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { ISO8601Date } from '~/types'
import type { Model, Response } from '~/types/api/v1'

export type AccountItemMeta = Model<{
  createdAt: ISO8601Date
  updatedAt: ISO8601Date
  title: string
  type: string
  accountItemMetaTypeId: string
}>

export const useFetchAccountItemMeta = () => {
  const metasUrl = '/me/account/accountItemMetas'

  const { get, post, put, deleteRequest } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) =>
    get<Response<AccountItemMeta[]>>({
      url: metasUrl,
      workspaceId,
    })

  const create = ({ workspaceId, typeId, title }: WorkspaceIdentifier & { typeId: string, title: string }) =>
    post<Response<AccountItemMeta>>({
      url: metasUrl,
      workspaceId,
      fetchOptions: {
        body: {
          data: {
            attributes: {
              title,
            },
            relationships: {
              accountItemMetaType: {
                type: 'accountItemMetaType',
                id: typeId,
              },
            },
          },
        },
      },
    })

  const createBatch = async ({
    workspaceId,
    typeId,
    metas,
  }: WorkspaceIdentifier & { typeId: string, metas: Array<{ title: string }> }) => {
    await Promise.allSettled(metas.map(
      async ({ title }) => await create({ workspaceId, typeId, title }),
    ),
    )
  }

  const update = ({ workspaceId, title, metaId }: WorkspaceIdentifier & { title: string, metaId: string }) =>
    put<Response<AccountItemMeta>>({
      url: `${metasUrl}/${metaId}`,
      workspaceId,
      fetchOptions: {
        body: {
          data: {
            id: metaId,
            attributes: { title },
          },
        },
      },
    })

  const updateBatch = async (
    {
      workspaceId,
      metas,
    }: WorkspaceIdentifier & { metas: Array<{ id: string, title: string }> }) => {
    await Promise.allSettled(metas.map(
      async ({ id, title }) => await update({ workspaceId, metaId: id, title }),
    ),
    )
  }

  const drop = ({ workspaceId, metaId }: WorkspaceIdentifier & { metaId: string }) => deleteRequest({
    url: `${metasUrl}/${metaId}`,
    workspaceId,
  })

  const dropBatch = async (
    {
      workspaceId,
      metas,
    }: WorkspaceIdentifier & { metas: Array<{ id: string }> }) => {
    await Promise.allSettled(metas.map(
      async ({ id }) => await drop({ workspaceId, metaId: id }),
    ),
    )
  }

  return {
    getAll,
    create,
    update,
    drop,
    createBatch,
    updateBatch,
    dropBatch,
  }
}
