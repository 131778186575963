import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { WorkspaceIdentifier } from '../types'
import type * as API from '~/types/api'
import type { CollectionRequestPaginationParams } from '~/types/api/v2'

type PartySearchResultData = App.Data.Document.Metadata.PartySearchResultData
export const responseDataTypeName: PartySearchResultData['_type'] = 'party_search_result'

export type ParametersGetAll = {
  'filter[query]'?: string
} & CollectionRequestPaginationParams

export const useFetchParty = () => {
  const url = '/v2/metadata/parties'

  const { get } = useFetchWithDefaults()

  type CollectionResponse<T> = API.v2.CollectionResponse<T>

  const getAll = (
    {
      params,
      workspaceId,
    }:
      WorkspaceIdentifier & {
        params?: ParametersGetAll
      },
  ) => get<CollectionResponse<PartySearchResultData>>({
    url,
    workspaceId,
    fetchOptions: {
      params,
    },
  })

  return {
    getAll,
  }
}
