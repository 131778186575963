import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { WorkspaceIdentifier } from '../types'
import type { CollectionRequestPaginationParams, CollectionResponse } from '~/types/api/v2'
import type { WithNegated } from '~/types/util/string'

export const responseDataTypeName: Contact['_type'] = 'contact'

type SortCriterion = 'updated_at'

// TODO DEV-6021 the model types are not generated, so we have to define them here
export interface ContactPerson {
  id: string
  _type: 'contact_person'
  first_name: string
  last_name: string
  title: string | null
  email: string | null
  phone: string | null
  street: string | null
  city: string | null
  post_code: string | null
  country: string | null
  notes: string | null
  companies?: Array<Omit<ContactCompany, 'persons'>>
  is_system: boolean
}

export interface ContactCompany {
  id: string
  _type: 'contact_company'
  name: string
  ident: string | null
  street: string | null
  city: string | null
  post_code: string | null
  country: string | null
  notes: string | null
  persons?: Array<Omit<ContactPerson, 'companies'>>
  is_system: boolean
}

export type PayloadHelper<T extends ContactCompany | ContactPerson> = Omit<T, 'id' | '_type' | 'is_system'>

export interface Contact {
  id: string
  _type: 'contact'
  updated_at: string
  person: ContactPerson
  company?: ContactCompany
}

export type ParametersGetAll = {
  sort?: WithNegated<SortCriterion>[]
  'filter[query]'?: string
} & CollectionRequestPaginationParams

export type ParametersGetEntity<TInclude extends 'persons' | 'companies'> = {
  include?: TInclude
  'filter[query]'?: string
} & CollectionRequestPaginationParams

export const useFetchContact = () => {
  const url = '/v2/contacts'
  const companies = '/v2/contact-companies'
  const persons = '/v2/contact-persons'

  const { get, post } = useFetchWithDefaults()

  const getAll = ({
    params,
    workspaceId,
  }:
    WorkspaceIdentifier & {
      params?: ParametersGetAll
    }) => get<CollectionResponse<Contact>>({
    url,
    workspaceId,
    fetchOptions: {
      params,
    },
  })

  const getCompanies = (
    { workspaceId, params }:
      WorkspaceIdentifier & {
        params?: ParametersGetEntity<'persons'>
      }) => get<CollectionResponse<ContactCompany>>({
    url: companies,
    workspaceId,
    fetchOptions: {
      params,
    },
  })

  const createCompany = ({
    workspaceId,
    payload,
  }:
    WorkspaceIdentifier & {
      payload: PayloadHelper<Omit<ContactCompany, 'persons'>>
      & { persons?: Array<{ id: string }> }
    }) =>
    post<{ data: ContactCompany }>({
      url: companies,
      workspaceId,
      fetchOptions: {
        body: payload,
      },
    })

  const createPerson = ({
    workspaceId,
    payload,
  }:
    WorkspaceIdentifier & {
      payload: PayloadHelper<Omit<ContactPerson, 'companies'>> & { companies?: Array<{ id: string }> }
    }) =>
    post<{ data: ContactPerson }>({
      url: persons,
      workspaceId,
      fetchOptions: {
        body: payload,
      },
    })

  return {
    getAll,
    getCompanies,

    createCompany,
    createPerson,
  }
}
