import { createQueryKeys } from '@lukemorales/query-key-factory'
import {
  type ParametersGetRecent,
  responseDataTypeName,
  useFetchRecentTemplate,
} from './useFetchRecentTemplate'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'
import type { Language } from '~/types'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    params,
    locale,
    workspaceId,
  }: {
    params?: MaybeRef<ParametersGetRecent>
    locale: MaybeRef<Language>
    workspaceId: MaybeRef<string>
  }) => ({ queryKey: [
    workspaceId,
    locale,
    { dataType: ['template', 'userTemplate'] },
    ...(params ? [params] : []),
  ] }),
})

const all = ({
  params,
  useQueryOptions = {},
}: {
  params?: MaybeRef<ParametersGetRecent>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
} = {}) => {
  const { getRecent } = useFetchRecentTemplate()
  const { activeWorkspaceId } = useAuth()
  const { language } = useLanguage()

  return useQuery({
    ...queryKeys.all({
      params,
      locale: language,
      workspaceId: activeWorkspaceId as MaybeRef<string>,
    }),
    queryFn: () => getRecent({
      params: unref(params),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryRecentTemplate = {
  all,
}
