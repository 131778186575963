import { createQueryKeys } from '@lukemorales/query-key-factory'
import { responseDataTypeName, useFetchBatch } from './useFetchBatch'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  byId: ({ id }: { id: MaybeRef<string> }) => ({ queryKey: [{ id }] }),
})

const byId = ({
  id,
  useQueryOptions,
}: {
  id: MaybeRef<string>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getById } = useFetchBatch()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.byId({ id }),
    queryFn: () => getById({
      id: unref(id),
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryBatch = {
  byId,
}
