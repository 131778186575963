import type { Story } from './general'

export const contentType = 'blogpost' as const

export interface BlogpostContent {
  _uid: string
  title: string
  intro: string
  author: string
  author_image: { filename: string, alt: string }
}

export type BlogpostStory = Story<BlogpostContent>
