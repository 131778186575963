import type { WorkspaceIdentifier } from '../types'
import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type * as API from '~/types/api'

type PropertyData = App.Data.Document.Metadata.PropertyData
export const responseDataTypeName: PropertyData['_type'] = 'property'

export const useFetchDocumentProperty = () => {
  const url = '/v2/metadata/attributes'

  const { get, put, post, deleteRequest } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: WorkspaceIdentifier) =>
    get<API.v2.CollectionResponse<PropertyData>>({
      url,
      workspaceId,
    })

  const update = (
    {
      id,
      payload,
      workspaceId,
    }:
      WorkspaceIdentifier & {
        id: string
        payload: App.Data.Payloads.Metadata.Properties.UpdatePropertyPayload
      },
  ) => put<API.v2.Response<PropertyData>>({
    url: `${url}/${id}`,
    fetchOptions: {
      body: payload,
    },
    workspaceId,
  })

  const create = (
    {
      payload,
      workspaceId,
    }:
      WorkspaceIdentifier & {
        payload: App.Data.Payloads.Metadata.Properties.CreatePropertyPayload
      },
  ) => post<API.v2.Response<PropertyData>>({
    url,
    fetchOptions: {
      body: payload,
    },
    workspaceId,
  })

  const drop = ({ workspaceId, id }: WorkspaceIdentifier & { id: string }) =>
    deleteRequest({
      url: `${url}/${id}`,
      workspaceId,
    })

  return {
    getAll,
    update,
    create,
    drop,
  }
}
