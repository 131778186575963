import type { WorkspaceIdentifier } from '../types'
import { useFetchWithDefaults } from '../useFetchWithDefaults'

export interface PriceListAddonPrice {
  _type: 'addon_price'
  currency_code: string
  id: string
  monthly_price: number
  period: number
  period_unit: string
  price: number
  price_id: string
  pricing_model: string
  title: string
}

export interface PriceListAddon {
  _type: 'addon'
  addon_id: string
  // eslint-disable-next-line ts/ban-types
  family_id: 'public' | string & {}
  id: string
  prices: PriceListAddonPrice[]
  title: string
}

export interface PriceListFeatureConfigOption {
  addon_id?: string
  type: 'option'
  quantity: Maybe<number>
}

interface PriceListFeatureConfigOptionGroup {
  label: string
  type: 'group'
  options: PriceListFeatureConfigOption[]
}

interface PriceListFeatureConfig {
  feature_id: string
  options: PriceListFeatureConfigOptionGroup[]
}

export interface PriceListFeature {
  id: string
  title: string
  description?: string
  only_featured?: boolean
}

interface PriceListFeatureItem {
  id: PriceListFeature['id']
  value: number | string
  description?: string
}

interface PriceListItemEntitlement {
  feature_id: string
  value: number | boolean
}

export interface PriceListItemPrice {
  id: string
  monthly_price: number
  period: number
  period_unit: string
  price: number
}

export interface PriceListItem {
  data: {
    features?: PriceListFeatureItem[]
    promoted_features?: PriceListFeatureItem['id'][]
    custom_monthly_price?: Maybe<number>
    upgrade_only?: boolean
  }
  entitlements: PriceListItemEntitlement[]
  id: string
  position: number
  prices: PriceListItemPrice[]
  title: string
}

export interface PriceList {
  id: string
  _type: 'price_list'
  name: Maybe<string>
  country: App.Internals.Enums.Country
  language: App.Internals.Enums.Language
  state: 'active' | 'draft' | 'hidden'
  // eslint-disable-next-line ts/ban-types
  data_version: 'v1' | string & {}
  data: {
    feature_configs?: PriceListFeatureConfig[]
    features?: PriceListFeature[]
  }
  addons: PriceListAddon[]
  items: PriceListItem[]
}

export const responseDataTypeName: PriceList['_type'] = 'price_list'

export const useFetchPriceList = () => {
  const { get } = useFetchWithDefaults()
  const url = '/v2/price-lists'

  const getByName = ({
    name,
    workspaceId,
  }: WorkspaceIdentifier & {
    name: string
  }) => get<PriceList>({
    url: `${url}/${name}`,
    workspaceId,
  })

  return {
    getByName,
  }
}
