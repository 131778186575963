import type { StoriesParams, StoriesResponseData, Story, StoryParams, StoryResponseData } from '~/types/storyblok/general'

export const responseDataTypeName = 'storyblok' as const
const storiesBaseUrl = 'https://api.storyblok.com/v2/cdn/stories'
const cv = Math.floor(Date.now() / 1000)

const mapRelationsToStory = <T extends Record<string, any>>(
  rels: any[],
  story: Story<T>,
  relationNames?: string | string[],
): Story<T> => {
  const ensureArray = (value: string | string[] = []) => Array.isArray(value) ? value : value.split(',')
  const relationNamesArray = ensureArray(relationNames || [])

  return !relationNamesArray.length
    ? story
    : {
        ...story,
        content: Object.entries(story.content).reduce((acc, [key, value]) => !relationNamesArray.includes(key)
          ? acc
          : {
              ...acc,
              [key]: Array.isArray(value)
                ? value.map(storyUUID => rels?.find(rel => rel.uuid === storyUUID) || storyUUID)
                : rels?.find(rel => rel.uuid === value) || value,
            }, {} as T),
      }
}

export const useFetchStoryblok = () => {
  // https://www.storyblok.com/docs/api/content-delivery/v2/stories/retrieve-a-single-story
  const getStory = <Content extends Record<string, any>>(identifier: string, storyParams?: StoryParams) => {
    const runtimeConfig = useRuntimeConfig()
    const token = runtimeConfig.public.storyblokToken
    const params: typeof storyParams = { ...storyParams, token, cv }

    return $fetch<StoryResponseData<Content>>(`${storiesBaseUrl}/${identifier}`, { params })
      .then(response => ({
        ...response,
        story: mapRelationsToStory(response.rels, response.story, params.resolve_relations),
      }))
  }

  // https://www.storyblok.com/docs/api/content-delivery/v2/stories/retrieve-multiple-stories
  const getStories = <Content extends Record<string, any>>(storiesParams?: StoriesParams) => {
    const runtimeConfig = useRuntimeConfig()
    const token = runtimeConfig.public.storyblokToken
    const params: typeof storiesParams = { ...storiesParams, token, cv }

    return $fetch<StoriesResponseData<Content>>(storiesBaseUrl, { params })
      .then(response => ({
        ...response,
        stories: response.stories.map(story => mapRelationsToStory(response.rels, story, params.resolve_relations)),
      }))
  }

  return {
    getStory,
    getStories,
  }
}
