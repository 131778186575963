import { type DocumentIdentifier, getUrlById as getDocumentByIdUrl } from '../useFetchDocument'
import { useFetchWithDefaults } from '~/composables/api/useFetchWithDefaults'
import type { Response } from '~/types/api/v2'

type SummaryData = App.Data.Document.SummaryData

export const responseDataTypeName: SummaryData['_type'] = 'summary'

export const useFetchDocumentSummary = () => {
  const getUrl = (documentId: string) => `${getDocumentByIdUrl(documentId)}/summarize`

  const { post } = useFetchWithDefaults()

  const getByDocumentId = ({ workspaceId, documentId }: DocumentIdentifier) =>
    post<Response<SummaryData>>({ url: getUrl(documentId), workspaceId })

  return {
    getByDocumentId,
  }
}
