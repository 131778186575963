import type { User } from '~~/types/auth'

const workspaceType = {
  company: 'company',
  consumer: 'consumer',
} as const

const getDefinedUserName = (user: User) => {
  if (user.attributes.name !== user.attributes.email)
    return user.attributes.name || ''

  return ''
}

export default defineNuxtPlugin(() => {
  // The following check is required due to the use of a child Nuxt app embedded within the app via an iframe.
  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.get('is-child-app') === 'true')
    return

  const { public: { intercomAppId } } = useRuntimeConfig()

  const { user, workspace } = useAuth()

  const ctxData = computed(() => {
    const data: Intercom_.IntercomSettings = {}
    if (user.value) {
      data.user_id = user.value.id
      data.name = getDefinedUserName(user.value)
      data.email = user.value.attributes.email
      data.user_hash = user.value.attributes.intercomUserHash
      data.language_override = user.value.attributes.language

      data.current_workspace = user.value.attributes.name
      data.workspace_type = workspaceType.consumer
      data.workspace_subscription_plan = undefined
      data.workspace_subscription_status = undefined
      data.workspace_partner = undefined
    }
    if (workspace.value) {
      data.current_workspace = workspace.value.attributes.displayName ?? undefined
      data.workspace_country = workspace.value.attributes.country
      data.workspace_type = workspace.value.attributes.name
        ? workspaceType.company
        : workspaceType.consumer
      data.workspace_subscription_plan = workspace.value.attributes.subscription_plan ?? undefined
      data.workspace_subscription_status = workspace.value.attributes.subscription_status
      data.workspace_partner = workspace.value.relationships?.partner?.data.attributes.type ?? undefined

      data.company = {
        id: workspace.value.id,
        name: workspace.value.attributes.displayName ?? '',
      }
    }
    return data
  })

  const boot = (opts: Omit<Intercom_.IntercomSettings, 'app_id'>) => {
    if (!(hasOwn(window, 'Intercom')))
      return

    window.Intercom('boot', {
      app_id: intercomAppId,
      ...opts,
    })
  }

  const mount = () => {
    if (hasOwn(window, 'Intercom'))
      return

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = `https://widget.intercom.io/widget/${intercomAppId}`
    script.defer = true
    script.onload = () => boot(ctxData.value)
    document.body.appendChild(script)
  }

  const callMethod = <Command extends Intercom_.IntercomCommand>(
    cmd: Command,
    ...params: Parameters<Intercom_.IntercomCommandSignature[Command]>
  ) => {
    if (!(window && hasOwn(window, 'Intercom')))
      return

    window.Intercom(cmd, ...params)
  }

  watch(() => ctxData, useDebounceFn((ctx) => {
    if (ctx.value.user_id)
      callMethod('update', ctx.value)
    else
      callMethod('shutdown')
  }), { deep: true })

  const route = useRoute()
  watch(() => route.fullPath, () => {
    const updateData: Intercom_.IntercomSettings = {
      user_id: user.value?.id,
      user_hash: user.value?.attributes.intercomUserHash,
    }
    callMethod('update', updateData)
  })

  mount()

  return {
    provide: {
      intercom: {
        call: callMethod,
      },
    },
  }
})
