import type { ISO8601Date } from '~/types'

export default () => {
  const { t } = useI18n()
  const { language } = useLanguage()
  const { workspace } = useAuth()
  const isLoadingConfigEnabled = computed(() => !!workspace.value)
  const { data: config } = useQueryConfig.current({
    useQueryOptions: {
      enabled: isLoadingConfigEnabled,
    },
  })

  const locale = computed(
    () => workspace.value
      ? `${language.value}-${workspace.value.attributes.country}`
      : language.value,
  )

  /**
   * Formats given number to currency string based on
   * current language and workspace's country.
   *
   * @throws {TypeError}  If no country configuration available
   */
  const formatCurrency = (
    amount: number,
    options?: Omit<Intl.NumberFormatOptions, 'style' | 'currency'>,
  ): string => new Intl.NumberFormat(locale.value, {
    ...options,
    style: 'currency',
    currency: config.value?.currency_code || 'EUR',
  }).format(amount)

  /**
   * Formats given date to date time string based on
   * current language and workspace's country.
   */
  const formatDate = (
    date: Date | ISO8601Date,
    options: Intl.DateTimeFormatOptions = { dateStyle: 'short' },
  ): string => {
    if (typeof date === 'string')
      date = new Date(date)

    return new Intl.DateTimeFormat(locale.value, options).format(date)
  }

  const formatRelativeTime = (
    value: number,
    unit: Intl.RelativeTimeFormatUnit,
    options: Intl.RelativeTimeFormatOptions = { numeric: 'auto' },
  ): string => new Intl.RelativeTimeFormat(locale.value, options).format(value, unit)

  const formatDateTime = (
    date: Date | ISO8601Date,
    options: Intl.DateTimeFormatOptions = { dateStyle: 'short', timeStyle: 'short' },
  ): string => formatDate(date, options)

  const getDisplayNames = (opts: Intl.DisplayNamesOptions) => (code: string) => {
    const names = new Intl.DisplayNames(locale.value, opts)
    return names.of(code)
  }

  const displayLanguageName = getDisplayNames({ type: 'language' })

  const getRegionName = (code: string) => {
    if (code.includes('-')) {
      switch (code) {
        case 'GB-ENG': return t('legislation.GB-ENG')
        case 'GB-NIR': return t('legislation.GB-NIR')
        case 'GB-SCT': return t('legislation.GB-SCT')
        case 'GB-WLS': return t('legislation.GB-WLS')
      }
    }

    return getDisplayNames({ type: 'region' })(code)
  }

  /**
   * sorts the given items by making use of Intl.Collator().compare
   * @param items the items to sort
   * @param getSortCriterionFromItemFunction
   *   a function that returns the sort criterion from each item in items
   * @param collatorOptions the options passed to the constructor of Intl.Collarotor
   */
  const sortIntl = <T>(
    items: Readonly<T[]>,
    getSortCriterionFromItemFunction = (item: T): string =>
      item as unknown as string,
    collatorOptions: Intl.CollatorOptions | undefined = undefined,
  ) =>
    [...items].sort((a, b) =>
      Intl.Collator(language.value, collatorOptions).compare(
        getSortCriterionFromItemFunction(a) || '',
        getSortCriterionFromItemFunction(b) || '',
      ),
    )

  return {
    locale,
    sortIntl,
    formatDate,
    getRegionName,
    formatCurrency,
    formatDateTime,
    formatRelativeTime,
    displayLanguageName,
  }
}
