import type { WorkspaceIdentifier } from '../types'
import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { SignableContract, UpdateContractPayload } from './types'
import { populateRelationships } from '~/utils/api/v1'
import type { Response } from '~/types/api/v1'
import type { Response as ResponseApiV2 } from '~/types/api/v2'

export const responseDataTypeName: SignableContract['type'] = 'contract-signature-contract'

interface LegacyContractCreateData {
  doSign: boolean
  finalize: boolean
  signMethod: App.Internals.Enums.SignatureMethod
  invite: boolean
  language: App.Internals.Enums.Language
  attachments: Array<{ content: string }>
  parties: Array<{
    firstName: string
    lastName: string
    companyTitle?: string
    includeCompletedInEmail: boolean
  }>
  attributes: {
    name: string
    date: string
  }
  relationships: {
    accountItemMetas: {
      data: Array<{ id: string }>
    }
  }
  meta: {
    content: string
    filename: string
  }
  senderName: string | null
  message: string | null
  remindDuration: string | null
  remindEnd: string | null
  expiresAt: string | null
}

export const getAllUrl = '/me/contract-signature'
export const getByIdUrl = (contractId: string) => `${getAllUrl}/${contractId}/_`

export type ContractIdentifier = WorkspaceIdentifier & { contractId: string }

export const defaultIncludeParams = {
  // included fields are fixed here to avoid additional effort that might
  // be obsolete once the endpoints are available in the v2 api
  include: ['signingSettings', 'client'],
}

const transformCreateContractDataToApiFormat = <T = any>(data: T, form?: FormData, namespace = ''): FormData => {
  const formdata = form || new FormData()

  if (typeof data === 'object' && !(data instanceof File)) {
    for (const key in data) {
      if (!hasOwn(data, key))
        continue

      const formKey = namespace ? `${namespace}[${key}]` : key
      transformCreateContractDataToApiFormat(
        data[key],
        formdata,
        formKey,
      )
    }
  }
  else if (Array.isArray(data)) {
    data.forEach((item, index) =>
      transformCreateContractDataToApiFormat(
        item,
        formdata,
        `${namespace}[${index}]`,
      ),
    )
  }
  else if (typeof data === 'boolean') {
    formdata.append(namespace, data ? '1' : '0')
  }
  else if (data instanceof File) {
    formdata.append(namespace, data)
  }
  else if (typeof data === 'string') {
    formdata.append(namespace, data)
  }
  else if (typeof data === 'number') {
    formdata.append(namespace, data.toString())
  }

  return formdata
}

export const useFetchContract = () => {
  const { get, put, post } = useFetchWithDefaults()

  const getById = ({ workspaceId, contractId }: ContractIdentifier) =>
    get<Response<SignableContract>>({
      url: getByIdUrl(contractId),
      workspaceId,
      fetchOptions: {
        params: defaultIncludeParams,
      },
    }).then(populateRelationships)

  const create = (
    { workspaceId, data }: WorkspaceIdentifier & { data: App.Data.Payloads.Contracts.CreateContractPayload },
  ) => post<ResponseApiV2<{ document: { id: string } }>>({
    url: '/v2/contracts?include=document',
    workspaceId,
    fetchOptions: {
      body: transformCreateContractDataToApiFormat(data),
    },
  })

  const createLegacy = (
    { workspaceId, data }: WorkspaceIdentifier & { data: LegacyContractCreateData },
  ) => post<Response<{ id: string }>>({
    url: '/me/contracts',
    workspaceId,
    fetchOptions: {
      body: {
        data,
      },
    },
  })

  const update = ({
    workspaceId,
    contractId,
    payload,
  }: ContractIdentifier & { payload: UpdateContractPayload }) =>
    put<Response<SignableContract>>({
      url: getByIdUrl(contractId),
      workspaceId,
      fetchOptions: {
        body: payload,
        params: defaultIncludeParams,
      },
    }).then(populateRelationships)

  const startStrongSigning = ({
    pin,
    contractId,
    workspaceId,
    permissionId,
  }: ContractIdentifier & { pin: string, permissionId: string }) =>
    post<{ data: { url: string } }>({
      url: `/contract-signature/${contractId}/${permissionId}/sign`,
      workspaceId,
      fetchOptions: {
        headers: {
          'x-pin': pin,
        },
      },
    })

  return {
    create,
    createLegacy,
    update,
    getById,
    startStrongSigning,
  }
}
