type CacheKey = 'record_balance' | 'config'

/**
 * This function should be used when the initial data is set.
 */
export const getLegacyCacheData = (key: CacheKey, activeWorkspaceId: string | null | undefined) => {
  if (!activeWorkspaceId)
    return

  try {
    return JSON.parse(
      sessionStorage.getItem(`${activeWorkspaceId}-${key}`) as string,
    ).value
  }
  catch (e) { }
}
