import camelCase from 'lodash.camelcase'

interface Urls {
  privacy_policy: string
  terms: string
  support_portal?: string
}

export default () => {
  const { workspace } = useAuth()
  const isLoadingConfigEnabled = computed(() => !!workspace.value)
  const { data: config } = useQueryConfig.current({
    useQueryOptions: {
      enabled: isLoadingConfigEnabled,
    },
  })

  const fallbackUrls: Urls = {
    privacy_policy: 'https://docue.com/en/privacy',
    terms: 'https://docue.com/en/terms',
  }

  const partnerUrls: Record<string, Urls> = {
    finago: {
      privacy_policy: 'https://finago.com/fi/tietosuojaseloste/',
      terms: 'https://finago.com/fi/finago-sopimuskone-kayttoehdot/',
    },
  }

  const getLink = (key: 'terms' | 'privacy_policy' | 'support_portal') => computed(() => {
    const partnerData = workspace.value?.relationships?.partner?.data
    const keyUrl = `${camelCase(key)}Url` as 'termsUrl' | 'privacyPolicyUrl'

    if (partnerData && hasOwn(partnerData, keyUrl))
      return partnerData.attributes[keyUrl] ?? undefined

    const partnerType = partnerData?.attributes?.type
    if (partnerType && hasOwn(partnerUrls, partnerType)
      && hasOwn(partnerUrls[partnerType], key)) {
      return partnerUrls[partnerType][key]
    }

    const countryConfigTermsKey = key === 'terms' ? 'contract_terms' : key
    return config.value?.[`link_${countryConfigTermsKey}`] ?? fallbackUrls[key as keyof typeof fallbackUrls]
  })

  return {
    terms: getLink('terms'),
    privacyPolicy: getLink('privacy_policy'),
    supportPortal: getLink('support_portal'),

    builder: (contractId: string) => `/builder/edit?cId=${contractId}`,
    document: (documentId: string) => `/2/documents/d/${documentId}`,

    template: (templateId: string) => `/2/template/${templateId}`,
    userTemplate: (templateId: string) => `/2/template/own/${templateId}`,

    documents: () => '/2/documents',
    view: (viewId: string) => `/2/documents/v/${viewId}`,
    view404: () => `/2/documents/v/not-found`,
    folder: (folderId: string, query: string = '') => `/2/documents/f/${folderId}${query}`,

    legacyTemplatesAll: () => '/templates/all',
    legacyArchiveItem: (itemId: string) => `/documents?accountItemId=${itemId}`,

    identify: () => '/identify',
    subscribe: () => '/subscribe',
  }
}
