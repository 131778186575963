import { useApiV2FetchFactory } from '../useApiV2FetchFactory'
import type { WithNegated } from '~/types/util/string'
import type { CollectionRequestPaginationParams } from '~/types/api/v2'

type DocumentTypeData = App.Data.Document.Metadata.DocumentTypeData
export const responseDataTypeName: DocumentTypeData['_type'] = 'document_type'

export type IncludableField = 'translations'

type SortCriterion = 'name' | `translations.${App.Internals.Enums.Language}`

export type ParametersGetAll = {
  sort?: WithNegated<SortCriterion>[]
} & CollectionRequestPaginationParams

export const useFetchDocumentType = () => {
  const url = '/v2/metadata/document-types'

  const { createGetAll } = useApiV2FetchFactory<DocumentTypeData, IncludableField>(url)

  return {
    getAll: createGetAll(),
  }
}
