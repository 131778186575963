import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { Response } from '~/types/api/v2'

export const responseDataTypeName: App.Data.BatchApi.BatchApiResponseData['_type'] = 'batch_api_response'

// payload types are rewritten from App.Data.Payloads.Documents.BatchApi.BatchApiPayload

export interface BatchApiPayloadAddTags {
  mutation: 'add_tags'
  value: string[]
  documents: string[]
}

export interface BatchApiPayloadRemoveTags {
  mutation: 'remove_tags'
  value: string[]
  documents: string[]
}

export interface BatchApiPayloadMoveToFolder {
  mutation: 'move_to_folder'
  value: string
  documents?: string[]
  folders?: string[]
}

export interface BatchApiPayloadTrash {
  mutation: 'trash'
  documents?: string[]
  folders?: string[]
}

export interface BatchApiPayloadByMutation {
  add_tags: BatchApiPayloadAddTags
  remove_tags: BatchApiPayloadRemoveTags
  move_to_folder: BatchApiPayloadMoveToFolder
  trash: BatchApiPayloadTrash
}

export interface BatchMutationVariables<Mutation extends App.Internals.Enums.BatchApiMutation> {
  workspaceId: string
  payload: BatchApiPayloadByMutation[Mutation]
}

export const useFetchBatch = () => {
  const url = '/v2/batches'
  const createMutationFn = <Mutation extends App.Internals.Enums.BatchApiMutation>() => ({
    workspaceId,
    payload,
  }: BatchMutationVariables<Mutation>) => {
    const { post } = useFetchWithDefaults()
    return post<Response<App.Data.BatchApi.BatchApiResponseData>>({
      url,
      workspaceId,
      fetchOptions: {
        body: payload,
      },
    })
  }

  const getById = ({
    id,
    workspaceId,
  }: {
    id: string
    workspaceId: string
  }) => {
    const { get } = useFetchWithDefaults()
    return get<Response<App.Data.BatchApi.BatchData>>({
      url: `${url}/${id}`,
      workspaceId,
    })
  }

  return {
    addTags: createMutationFn<'add_tags'>(),
    removeTags: createMutationFn<'remove_tags'>(),
    moveToFolder: createMutationFn<'move_to_folder'>(),
    trash: createMutationFn<'trash'>(),
    getById,
  }
}
