import { createQueryKeys } from '@lukemorales/query-key-factory'
import { type BalanceData, useFetchBalance } from './useFetchBalance'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys('balance', {
  all: ({ workspaceId }: { workspaceId: MaybeRef<string> }) => ({
    queryKey: [workspaceId],
  }),
})

const all = ({ useQueryOptions = {} }: { useQueryOptions?: UseQueryOptionsWithoutQueryFn } = {}) => {
  const { getAll } = useFetchBalance()
  const { activeWorkspaceId } = useAuth()

  const cachedData = getLegacyCacheData('record_balance', activeWorkspaceId.value)

  return useQuery({
    ...queryKeys.all({ workspaceId: activeWorkspaceId as MaybeRef<string> }),
    queryFn: () => getAll({ workspaceId: unref(activeWorkspaceId) ?? '' }),
    enabled: () => !!unref(activeWorkspaceId),
    select: ({ balance }) => balance,
    ...(
      cachedData
        ? {
            initialData: (): BalanceData => ({ balance: cachedData }),
            initialDataUpdatedAt: 0, // to start fetching in the background
          }
        : {}
    ),
    ...useQueryOptions,
  })
}
export const useQueryBalance = ({
  all,
})
