export default defineNuxtRouteMiddleware(async (to) => {
  if (!to.path.startsWith('/2/documents'))
    return

  const { workspace } = useAuth()

  if (!workspace.value?.attributes.root_folder_id) {
    navigateTo('/documents', { external: true })
    return abortNavigation()
  }
})
