import { createQueryKeys } from '@lukemorales/query-key-factory'
import { responseDataTypeName, useFetchWorkspacePermission } from './useFetchWorkspacePermission'
import type { IncludableField, ParametersGetAll, ParametersGetAllUnpaginated } from './useFetchWorkspacePermission'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({ // TODO share keys https://github.com/lukemorales/query-key-factory/issues/72
    workspaceId,
    fieldsToInclude,
    params,
  }:
  {
    workspaceId: MaybeRef<string>
    fieldsToInclude?: string[]
    params?: MaybeRef<ParametersGetAll>
  },
  ) => ({
    queryKey: [
      workspaceId,
      ...(fieldsToInclude ? [{ fieldsToInclude: fieldsToInclude.sort() }] : []),
      ...(params ? [params] : []),
    ],
  }),

  byId: ({
    id,
    fieldsToInclude,
  }:
  {
    id: MaybeRef<string>
    fieldsToInclude?: string[]
  },
  ) => ({
    queryKey: [
      id,
      ...(fieldsToInclude ? [{ fieldsToInclude: fieldsToInclude.sort() }] : []),
    ],
  }),

  allUnpaginated: ({
    params,
    fieldsToInclude,
    workspaceId,
  }: {
    params?: MaybeRef<ParametersGetAllUnpaginated>
    fieldsToInclude?: IncludableField[]
    workspaceId: MaybeRef<string>
  }) => ({
    queryKey: [{
      workspaceId,
      ...(fieldsToInclude ? { fieldsToInclude: fieldsToInclude.sort() } : {}),
      ...(params ? { params } : {}),
    }],
  }),
})

const all = <T extends IncludableField[] = []>(
  {
    params,
    fieldsToInclude,
    useQueryOptions = {},
  }: {
    params?: MaybeRef<ParametersGetAll> | undefined
    fieldsToInclude?: T
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  } = {},
) => {
  const { getAll } = useFetchWorkspacePermission()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.all({
      workspaceId: activeWorkspaceId as MaybeRef<string>,
      fieldsToInclude,
      params,
    }),
    queryFn: () => getAll({
      params: unref(params),
      workspaceId: unref(activeWorkspaceId) ?? '',
      fieldsToInclude: fieldsToInclude ?? [],
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

const allUnpaginated = <T extends IncludableField[] = []>(
  {
    params,
    fieldsToInclude,
    useQueryOptions = {},
  }: {
    params?: MaybeRef<ParametersGetAllUnpaginated> | undefined
    fieldsToInclude?: T
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  } = {},
) => {
  const { getAllUnpaginated } = useFetchWorkspacePermission()
  const { activeWorkspaceId } = useAuth()

  return useQuery(
    {
      ...queryKeys.allUnpaginated({
        workspaceId: activeWorkspaceId as MaybeRef<string>,
        fieldsToInclude,
        params,
      }),
      queryFn: async context => getAllUnpaginated(context.queryKey[2]),
      enabled: () => !!unref(activeWorkspaceId),
      ...useQueryOptions,
    },
  )
}

const byId = <T extends IncludableField[]>(
  {
    id,
    fieldsToInclude,
    useQueryOptions = {},
  }:
  {
    id: MaybeRef<string>
    fieldsToInclude: T
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  },
) => {
  const { getById } = useFetchWorkspacePermission()
  const { activeWorkspaceId } = useAuth()

  return useQuery({
    ...queryKeys.byId({
      id,
      fieldsToInclude,
    }),
    queryFn: () => getById({
      id: unref(id),
      fieldsToInclude,
      workspaceId: unref(activeWorkspaceId) || '',
    }),
    enabled: () => !!unref(activeWorkspaceId),
    ...useQueryOptions,
  })
}

export const useQueryWorkspacePermission = {
  all,
  allUnpaginated,
  byId,
}
