import { useFetchWithDefaults } from '../useFetchWithDefaults'
import type { Model, Response } from '~/types/api/v1'

export type TemplateCollectionData = Model<{
  name: string
}>

export const responseDataTypeName: TemplateCollectionData['type'] = 'collection'

export const useFetchTemplateCollection = () => {
  const getUrl = (workspaceId: string) => `/me/companies/${workspaceId}/templateCollections`

  const { get } = useFetchWithDefaults()

  const getAll = ({ workspaceId }: { workspaceId: string }) =>
    get<Response<TemplateCollectionData[]>>({ url: getUrl(workspaceId), workspaceId })

  return {
    getAll,
  }
}
