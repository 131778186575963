import type { WorkspaceIdentifier } from '../types'
import { useFetchWithDefaults } from '../useFetchWithDefaults'

export const responseDataTypeName: App.Data.Configuration.ConfigurationData['_type'] = 'configuration'

export const useFetchConfig = () => {
  const url = '/v2/configuration'

  const { get } = useFetchWithDefaults()

  const getCurrent = ({ workspaceId }: WorkspaceIdentifier) =>
    get<{ data: App.Data.Configuration.ConfigurationData }>({ url, workspaceId })

  return {
    getCurrent,
  }
}
