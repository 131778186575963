import type { FetchContext, FetchResponse } from 'ofetch'

export const useApiInterceptor = () => {
  const { apiToken, activeWorkspaceId } = useAuth()

  const invalidAuthResponseInterceptor = (context: FetchContext) => {
    const response = context.response as FetchResponse<
      Omit<App.Data.ErrorData, 'error_type'> & {
        error_type: App.Internals.Enums.Errors.AuthErrorType | App.Internals.Enums.Errors.ErrorType
      }
    >

    if (response?.status === 401
      || (response?.status === 403 && response._data?.error_type === 'auth_restrictions_not_met')) {
      // We are relying on app1 middleware handling cookie removal when accessed without a token
      apiToken.value = null
      activeWorkspaceId.value = null
      location.assign('/login')
    }
  }

  return {
    invalidAuthResponseInterceptor,
  }
}
