import { createQueryKeys } from '@lukemorales/query-key-factory'
import { responseDataTypeName, useFetchStoryblok } from './useFetchStoryblok'
import type { StoriesParams, StoryParams } from '~/types/storyblok/general'
import type { AppConfigContent, ResolvableRelation as ResolvableAppConfigRelation } from '~/types/storyblok/appConfig'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'
import type { BlogpostContent } from '~/types/storyblok/blogpost'
import { contentType as contentTypeAppConfig } from '~/types/storyblok/appConfig'
import { contentType as contentTypeBlogpost } from '~/types/storyblok/blogpost'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  appConfig: ({ country, params }: { country: MaybeRef<App.Internals.Enums.Country | undefined>, params?: MaybeRef<StoryParams> }) => ({
    queryKey: [{ country, params }],
  }),
  blogposts: ({ params }: { params?: MaybeRef<StoriesParams> }) => ({
    queryKey: [{ params }],
  }),
})

const getBlogposts = ({ params: storiesParams, useQueryOptions }: {
  params?: MaybeRef<Omit<StoriesParams, 'content_type'>>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getStories } = useFetchStoryblok()
  const params = computed(() => ({ ...unref(storiesParams), content_type: contentTypeBlogpost }))

  return useQuery({
    queryKey: queryKeys.blogposts({ params }).queryKey,
    queryFn: () => getStories<BlogpostContent>(unref(params)),
    ...useQueryOptions,
  })
}

const getAppConfig = ({ country, params: storyParams, useQueryOptions }: {
  country: MaybeRef<App.Internals.Enums.Country | undefined>
  params?: MaybeRef<Omit<StoryParams, 'find_by'> & { resolve_relations: ResolvableAppConfigRelation[] }>
  useQueryOptions?: UseQueryOptionsWithoutQueryFn
}) => {
  const { getStory } = useFetchStoryblok()
  const slug = computed(() => unref(country) ? `data/app-config-${unref(country)?.toLowerCase()}` : '')
  const enabled = computed(() => !!unref(country))
  const params = computed(() => ({ ...unref(storyParams), content_type: contentTypeAppConfig }))

  return useQuery({
    queryKey: queryKeys.appConfig({ country, params }).queryKey,
    queryFn: () => getStory<AppConfigContent>(slug.value, unref(params)),
    ...useQueryOptions,
    enabled,
  })
}

export const useQueryStoryblok = {
  getBlogposts,
  getAppConfig,
}
