import { createQueryKeys } from '@lukemorales/query-key-factory'
import { type IncludableField, type ParametersGetAll, responseDataTypeName, useFetchDocumentType } from './useFetchDocumentType'
import type { UseQueryOptionsWithoutQueryFn } from '~/types/util/tanstack-query'

export const queryKeys = createQueryKeys(responseDataTypeName, {
  all: ({
    workspaceId,
    fieldsToInclude,
    params,
  }:
  {
    workspaceId: MaybeRef<string>
    fieldsToInclude?: IncludableField[]
    params?: MaybeRef<ParametersGetAll>
  },
  ) => ({
    queryKey: [
      workspaceId,
      ...(fieldsToInclude ? [{ fieldsToInclude: fieldsToInclude.sort() }] : []),
      ...(params ? [params] : []),
    ],
  }),
})

const all = <T extends IncludableField[] = []>(
  {
    params,
    fieldsToInclude,
    useQueryOptions = {},
  }: {
    params?: MaybeRef<ParametersGetAll> | undefined
    fieldsToInclude?: T
    useQueryOptions?: UseQueryOptionsWithoutQueryFn
  } = {},
) => {
  const { getAll } = useFetchDocumentType()
  const { activeWorkspaceId } = useAuth()

  return useQuery(
    {
      ...queryKeys.all({
        workspaceId: activeWorkspaceId as MaybeRef<string>,
        fieldsToInclude,
        params,
      }),
      queryFn: () => getAll({
        params: unref(params),
        workspaceId: unref(activeWorkspaceId) ?? '',
        fieldsToInclude: fieldsToInclude ?? [],
      }),
      enabled: () => !!unref(activeWorkspaceId),
      ...useQueryOptions,
    },
  )
}

export const useQueryDocumentType = {
  all,
}
